<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main a-fs-14 projectadd">
      <el-form ref="projectForm" :model="projectForm" :rules="rules" style="max-width: 1200px">
        <div class="a-fs-16 a-fw-700">基本信息</div>
        <el-row>
          <el-col :span="10">
            <el-form-item prop="name">
              <le-input
                v-model="projectForm.name"
                label="项目名称"
                placeholder="请填写"
                :maxlength="20"
                :notnull="true"
              ></le-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="companyId">
              <le-company-under-select
                v-model="projectForm.companyId"
                label="所属商户"
                readonly
              ></le-company-under-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="a-fs-16 a-fw-700 a-mt-36">分成比例</div>
        <project-add-rate-fixed
          ref="fixedRateComp"
          v-model="projectForm.detail"
          :income-id="incomeId"
        ></project-add-rate-fixed>
        <div class="a-line-t-e0 footerBox">
          <el-button type="primary" class="a-ml-24 a-mt-15" @click="onSubmit">立即提交</el-button>
          <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerCancle">
            取消
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ProjectAddRateFixed from "./project-add-rate-fixed.vue";

export default {
  name: "ProjectEdit",

  components: {
    "project-add-rate-fixed": ProjectAddRateFixed,
  },

  data() {
    this.incomeId = 0;
    return {
      projectForm: {
        id: "",
        name: "",
        companyId: null,
        detail: [],
        rateType: 1,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        companyId: [
          {
            required: true,
            message: "请选择项目所属商户",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },

  activated() {
    const incomeId = parseInt(this.$route.query.incomeId, 10);
    if (incomeId && incomeId !== this.incomeId) {
      this.incomeId = incomeId;
      this.getIncomeDetail();
    }
  },

  methods: {
    getIncomeDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
          params: {
            incomeId: this.incomeId,
          },
        })
        .then(({ data }) => {
          Object.assign(this.projectForm, {
            id: data.id,
            name: data.name,
            companyId: data.companyId,
            rateType: data.rateType,
          });
        });
    },

    onSubmit() {
      const that = this;
      this.$refs.projectForm.validate((valid) => {
        if (valid) {
          that.$refs.fixedRateComp.$refs.fixRateForm.validate((fixedRateValid) => {
            if (fixedRateValid) {
              that.doSubmit();
            }
          });
        }
      });
    },

    doSubmit() {
      const data = {
        ...this.projectForm,
        detail: this.projectForm.detail.map((item) => {
          return {
            ...item,
            rate: item.rate * 100,
            serNo: item.serNo,
          };
        }),
      };

      this.$Axios
        ._post({
          url: this.$Config.apiUrl.changeIncome,
          params: data,
        })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "修改项目成功",
            type: "success",
          });
          if (this.projectForm.id) {
            this.$router.push({
              path: "/project/project-detail",
              query: {
                id: this.incomeId,
              },
            });
          } else {
            this.$router.back();
          }
          this.$store.dispatch("delView", this.$route);
        })
        .catch((error) => {
          this.$notify({
            title: "失败",
            message: error.result.message,
            type: "error",
          });
        });
    },

    //取消按钮
    handlerCancle() {
      this.$confirm("确定取消修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/project/project-list");
          this.$store.dispatch("delView", this.$route);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.projectadd {
  padding-bottom: 100px;
  .footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    padding-bottom: 24px;
  }

  /deep/ .s-search-label {
    width: 130px !important;
  }

  /deep/ .el-input__inner {
    width: 100%;
  }

  /deep/ .el-form-item__error {
    left: 150px;
  }
}
</style>
